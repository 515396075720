<template>
  <div class="MapLocationViewer MapLocationSelection">
    <b-alert
      :show="noMatch"
      variant="danger"
    >
      Het opgegeven adres leverde geen locatie op binnen de postcode <strong>{{ postalcode }}</strong>. Controleer of het adres juist is ingevoerd.
    </b-alert>
    <b-alert
      :show="!hasCoordinates && invalidCoordinates"
      variant="danger"
    >
      De opgegeven coördinaten zijn onjuist!.
      <b-button
        variant="link"
        class="p-0"
        style="text-decoration: underline"
        @click="getCoordinatesByAddress"
      >
        <small><b-icon icon="search" /></small>
        Haal de coördinaten op
      </b-button>
      of voer de coordinaten handmatig in.
    </b-alert>
    <b-alert
      :show="hasCoordinates && !hasValidRequestCoordinates"
      variant="warning"
    >
      De opgegeven coördinaten zijn onjuist!
      <small>
        <div>Breedtegraad: <strong>{{ request.coordinates.lat }}</strong></div>
        <div>Lengtegraad: <strong>{{ request.coordinates.lng }}</strong></div>
      </small>
      Gebaseerd op het opgegeven adres, de nieuwe coördinaten zijn voorgesteld.
    </b-alert>
    <Coordinates
      v-if="isUserCPO"
      :values="coordinatesValues"
      :request="request"
      :disabled="isCoordinatesDisabled"
      @pin-location="handlePinLocation"
      @coordinates="handleLocationCoordinates"
    />

    <b-aspect :aspect="aspect">
      <MapBox
        :access-token="accessToken"
        :map-style.sync="mapStyle"
        :options="mapOptions"
        @load="onMapLoaded"
      >
        <LayerRealisationProcesses
          v-if="!isLoading"
          :key="`LayerRealisationProcesses-${componentKey}`"
          :municipality-code="municipalityCode"
          @click="handleOpenRealisationPopup"
        />

        <LayerRequests
          v-if="hasCoordinates"
          :uuid="uuid"
          :coordinates="coordinatesValues"
          :municipality-code="municipalityCode"
        />

        <LayerChargingpoints :municipality-code="municipalityCode" />

        <PopupRequest :record="{}" />

        <PopupRealisationProcess @close="handleCloseRealisationProcessPopup" />

        <PopupChargingLocation />
      </MapBox>
      <div
        v-if="isLoading"
        :class="['mapbox-loader', { isLoading }]"
      >
        <b-spinner
          variant="secondary"
          style="width: 2rem; height: 2rem"
        />
      </div>
    </b-aspect>

    <LegendRequest />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import MapBox from '@/components/common/MapBox.vue';
import LayerChargingpoints from '@/components/map/LayerChargingpoints.vue';
import LayerRequests from '@/components/map/LayerRequests.vue';
import LayerRealisationProcesses from '@/components/map/LayerRealisationProcesses.vue';
import PopupRealisationProcess from '@/components/map/PopupRealisationProcess.vue';
import PopupRequest from '@/components/map/PopupRequest.vue';
import PopupChargingLocation from '@/components/map/PopupChargingLocation.vue';
import Coordinates from '@/components/definitions/Coordinates.vue';
import { getCoordinatesFromAddress } from '@/services/geocoder';
import { mapActions } from 'vuex';

import { formatPostalCode } from '@/helpers/string';
import { isValidLatitude, isValidLongitude } from '@/services/validation'
import LegendRequest from '@/components/map/LegendRequest.vue';
import { IconButtonControl } from '@/services/mapbox';

/**
 * Shows
 *  - the active Request location,
 *  (- the other Requests)
 *  - the existing chargingpoints
 */
export default {
  name: 'MapRequest',
  components: {
    MapBox,
    LayerChargingpoints,
    LayerRequests,
    LayerRealisationProcesses,
    PopupRealisationProcess,
    PopupChargingLocation,
    PopupRequest,
    LegendRequest,
    Coordinates
  },
  // TODO: Remove dependency injection
  provide() {
    return {
      registerFormField: null,
      unregisterFormField: null
    };
  },
  props: {
    /**
     * The Request Uuid
     */
    uuid: {
      type: String,
      required: true
    },
    aspect: {
      type: String,
      default: '16:10'
    }
  },
  data() {
    return {
      /**
       * MapBox instance
       */
      map: null,

      /**
       * MapBox SDK reference
       */
      mapbox: null,

      /**
       * MapBox is ready
       */
      loaded: false,

      /**
       * MapBox config
       */
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,

      /**
       * A default Mapbox Marker on the Request address position
       */
      requestMarker: null,

      /**
       * MapBox geocoder field
       */
      geocoder: null,

      /**
       * The uuid of the clicked realisation process
       */
      realisationUuid: null,

      /**
       * Reference to icon btn control
       */
      iconBtnControl: null,

      componentKey: 0,
      isCoordinatesDisabled: false,
      saveRequestError: false,
      coordinatesValues: {
        Longitude: '',
        Latitude: ''
      },
      isLoading: false,
      hasCoordinates: false,
      invalidCoordinates: false,
    };
  },
  computed: {
    ...mapGetters('chargingpoints', ['getActiveMunicipality']),
    ...mapGetters('requests', ['requestByUuid']),
    ...mapGetters('tenant', ['isCurrentTenant', 'getMapOptions', 'getMonitoringConfig', 'getMunicipalityOptions']),
    ...mapGetters('user', ['hasRole']),
    request() {
      return this.requestByUuid({ uuid: this.uuid });
    },
    postalcode() {
      return this.request && this.request.address && this.request.address.postalCode
        ? formatPostalCode(this.request.address.postalCode)
        : '-';
    },
    municipalityCode() {
      return (
        this.request.geographyCode ||
        this.request.address.municipality?.code ||
        (!isNaN(Number(this.request.address.municipality)) && this.request.address.municipality) || // Sometimes comes as a city name
        this.request.municipalityCode ||
        this.request.raw.MunicipalityCode ||
        this.request.raw.Municipality ||
        this.request.raw.municipalityCode
      );
    },
    noMatch() {
      return this.request?.coordinates?.nomatch;
    },
    hasValidRequestCoordinates () {
      const { lat, lng } = this.request.coordinates
      return isValidLatitude(lat) && isValidLongitude(lng)
    },
    mapOptions() {
      const center = this.hasCoordinates
        ? [this.coordinatesValues.Longitude, this.coordinatesValues.Latitude]
        : this.getMapOptions.center || [4.9041, 52.3676];

      return {
        center,
        zoom: this.hasCoordinates ? 14 : this.getMapOptions.zoom || 10
      };
    },
    isUserCPO() {
      return this.hasRole({ role: 'cpo' });
    }
  },
  watch: {
    $route: {
      handler() {
        this.componentKey++;
      }
    },
    municipalityCode: {
      immediate: true,
      async handler(code) {
        const hasMunicipalityCode = this.getMunicipalityOptions.some(o => o.value === code);

        if (hasMunicipalityCode && this.getActiveMunicipality !== code) {
          this.isLoading = true;
          await this.loadChargingPointsByCode({ code: this.municipalityCode });
          this.$store.dispatch('chargingpoints/setActiveMunicipality', { code });
          this.isLoading = false;
        }

        if (code && !hasMunicipalityCode && this.request.status === 2) {
          // status open
          this.$store.dispatch('tenant/setToast', {
            vm: this,
            message: `Behandelende gemeente ${this.request.address.city} is niet gevonden.`,
            error: false,
            variant: 'warning',
            delay: 100
          });
        } else if (code && hasMunicipalityCode) {
          const requestMunicipality = this.request.address?.municipality?.name || this.request.address?.municipality || this.request.address?.city || ''

          const hasExistingMunicipality = this.getMunicipalityOptions.some(mun => mun.text === requestMunicipality?.trim())
          !hasExistingMunicipality && this.$emit('activeMunicipality', code)
        }
      }
    },
    /**
     * Whether the loaded request is lacking or invalid coordinates
     */
    coordinatesValues: {
      immediate: true,
      deep: true,
      handler (coords) {
        this.hasCoordinates = isValidLatitude(coords.Latitude) && isValidLongitude(coords.Longitude)
      }
    }
  },
  created() {
    this.coordinatesValues.Longitude = this.request.coordinates.lng;
    this.coordinatesValues.Latitude = this.request.coordinates.lat;
  },

  beforeDestroy() {
    if (this.requestMarker) {
      this.requestMarker.remove();
    }
  },
  methods: {
    ...mapActions('chargingpoints', ['loadChargingPointsByCode']),
    async onMapLoaded({ map, mapbox }) {
      this.loaded = true;
      this.map = map;
      this.mapbox = mapbox;

      // Add the GeoCoder plugin
      // This geocoder is only for navigation purposes
      // License restrictions prevent other usage
      this.geocoder = new MapboxGeocoder({
        accessToken: this.accessToken,
        mapboxgl: this.mapbox,

        countries: this.getMapOptions.countries || 'nl',
        types: 'address',
        language: 'nl',

        collapsed: true,
        placeholder: 'Zoek op adres',
        marker: false
      });
      this.map.addControl(this.geocoder);

      if (this.getMonitoringConfig?.enabled) {
        this.iconBtnControl = new IconButtonControl({
          callback: this.handleIconBtn
        });
        this.map.addControl(this.iconBtnControl);
      }

      if (this.hasCoordinates) {
        this.addMarker();
      }

      const filters = {
        activeMunicipality: this.municipalityCode,
        chargingpoints: [],
        realisations: [],
        requests: []
      };

      this.$store.dispatch('realisations/filterRealisations', { filters });
      this.$store.dispatch('chargingpoints/filterChargingPoints', { filters });
      this.$store.dispatch('requests/filterRequests', { filters });

      if (this.hasCoordinates) {
        const coords = this.coordinatesValues
        this.handlePinLocation({ lng: coords.Longitude, lat: coords.Latitude })
      } else {
        this.getCoordinatesByAddress()
      }
    },
    async getCoordinatesByAddress () {
      if (Object.keys(this.request.address).length) {
        const coords = await getCoordinatesFromAddress({ address: this.request.address })
          .catch(err => console.log(err))

        this.handlePinLocation({ lng: coords[0], lat: coords[1] })
      }
    },
    addMarker() {
      if (this.requestMarker) return;
    },
    handleOpenRealisationPopup({ uuid }) {
      this.realisationUuid = uuid;
    },
    handleCloseRealisationProcessPopup() {
      this.realisationUuid = null;
    },

    handleIconBtn() {
      this.$emit('open');
    },

    handlePinLocation(event) {
      this.invalidCoordinates = false
      this.coordinatesValues.Longitude = event.lng;
      this.coordinatesValues.Latitude = event.lat;

      this.$nextTick(() => {
        if (this.hasCoordinates) {
          this.map.flyTo({
            center: this.hasCoordinates ? event : this.getMapOptions.center,
            zoom: 14,
            duration: 2000,
            essential: true
          });
        } else {
          this.invalidCoordinates = true
        }
      })
    },

    async handleLocationCoordinates(event) {
      this.isCoordinatesDisabled = true;
      this.saveRequestError = false;

      const payload = {
        ref: this.request.ref,
        data: {
          source: 'manual',
          coordinates: { nomatch: false, iteration: 0, ...event }
        }
      };
      let coordinates = {};

      const token = await this.$auth.getTokenSilently();
      try {
        const api = await fetch('/api/requestcoordinates', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(payload)
        });

        const response = await api.json();
        coordinates = response.data.record.data.baseData.coordinates;
        if (api.ok) {
          this.$store.dispatch('requests/loadRequestCoordinates', {
            coordinates,
            ref: payload.ref
          });
        }
      } catch (error) {
        this.saveRequestError = true;
      } finally {
        this.isCoordinatesDisabled = false;
        const message = this.saveRequestError ? 'Er is iets fout gegaan!' : 'Coördinaten zijn opgeslagen!';

        this.notification({ message });
      }
    },

    notification({ message }) {
      this.$bvToast.toast(message, {
        title: this.saveRequestError ? 'Fout!' : 'Succes!',
        variant: this.saveRequestError ? 'danger' : 'success',
        toaster: 'b-toaster-top-center',
        solid: true
      });
    }
  }
};
</script>

<style lang="scss">
.Legend__title {
  display: block;
  padding-top: 1.5rem;
}
.Legend__list {
  list-style-type: none;
  padding: 0.5rem 0 0;
  margin: 0;

  li {
    margin-top: 0.5rem;

    div {
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
}

.IconBtn {
  position: relative;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  color: #757575;

  > svg {
    position: absolute;
    top: 8px;
    left: 8px;
  }

  &:hover {
    color: black;
  }
}
</style>
