<template>
  <div class="ProfileCard pt-4">
    <p class="d-flex align-items-center justify-content-between px-4">
      <strong>
        Uw gebruikersprofiel
      </strong>
      <span
        class="ProfileCard__logout d-inline-flex"
        @click="logout"
      >
        <span>Uitloggen</span>
        <SvgIcon
          class="ml-2"
          icon="sign-out-regular"
        />
      </span>
    </p>
    <p class="d-flex align-items-start px-4">
      <SvgIcon
        icon="user-regular"
        :has-fill="false"
        class="mr-3 mt-1"
      />
      <span class="d-flex flex-wrap flex-shrink-1 flex-column">
        {{ username }}
        <small v-if="organization">Organisatie: {{ organization }}</small>
      </span>
    </p>
    <p
      v-if="email"
      class="d-flex align-items-center px-4"
    >
      <SvgIcon
        icon="envelope-regular"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        {{ email }}
      </span>
    </p>
    <div class="mx-4 mb-3">
      <div class="d-flex border-bottom mb-2">
        <strong class="flex-3">Rol</strong>
        <strong class="flex-7">Gemeentes</strong>
        <strong class="flex-7">Toegang tot</strong>
      </div>
      <div
        v-if="activeRole"
        class="d-flex mb-2"
      >
        <ul class="pl-3 flex-3">
          <li>
            {{ roleLabels[activeRole] }}
          </li>
        </ul>
        <ul class="pl-3 flex-7">
          <li
            v-for="municipality in roles[activeRole]"
            :key="municipality.value || municipality"
          >
            <span v-if="municipality.length">{{ municipality }}</span>
            <span v-else>{{ municipality.text }} ({{ municipality.value }})</span>
          </li>
        </ul>
        <ul class="pl-3 flex-7">
          <li
            v-for="feature in getRoleFeatures({ role: activeRole })"
            :key="feature"
          >
            {{ feature }}
          </li>
        </ul>
      </div>
    </div>
    <PasswordBox @processing="handleProcessing" />
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon.vue'
import PasswordBox from '@/components/profile/PasswordBox.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ProfileCard',
  components: { PasswordBox, SvgIcon },
  data () {
    return {
      roles: {
        admin: [],
        cpo: [],
        municipality: [],
        // RVB //
        developer: [],
        caseworker: [],
        casevieuwer: [],
        customer: []
      },
      roleLabels: {
        admin: 'Beheerder',
        cpo: 'Cpo',
        municipality: 'Gemeente',
        allMunicipalities: 'Alle gemeentes',
        developer: 'Ontwikkelaar',
        caseworker: 'Medewwerker',
        casevieuwer: 'Bezoeker',
        customer: 'Klant'
      },
      featureAccess: {
        chargingpoints: 'Laadpalen',
        requestlist: 'Aanvragen',
        requestlocations: 'Aanvraag inzicht',
        realisationlist: 'Processen',
        realisationbtn: 'Proces aanmaken',
        realisationcomment: 'Opmerkingen',
        canConfirmTrafficDecisions: 'VKB besluit',
        canDeleteProcess: 'Proces verwijderen',
        canDeleteListedProcesses: 'Processen verwijderen (lijst)',
        canChangeCommentStatus: 'Opmerkingstatus wijzigen',
        canChangeStatus: 'Processtatus wijzigen',
        automatedViews: 'Aanzichten genereren',
        generateStartDoc: 'Startdocument genereren',
        canAccessAssetManagement: 'Locaties',
        admin: 'Beheerpaneel',
        reporting: 'Rapportages',
        manuals: 'Handleidingen',
        eventlog: 'Logboeken',
        export: 'Export',
        privateProcess: 'Privaat proces'
      },
    }
  },
  computed: {
    ...mapGetters('overlay', ['isOverlayVisible']),
    ...mapGetters('tenant', ['getFeatureAccess', 'getMunicipalityOptions']),

    activeRole () {
      return Object.keys(this.roles).find(key => this.roles[key].length)
    },
    username() {
      return this.$auth.user.name
    },
    email() {
      return this.$auth.user.email || this.$auth.user['https://evtools.nl/email']
    },
    organization () {
      const identity = this.$auth.user['https://evtools.nl/identity']
      return identity?.uuid && `${identity?.label} (${identity?.category})`
    },
    tenantRoles () {
      return this.$auth.user['https://evtools.nl/roles'].find(role => role.tenant === process.env.VUE_APP_TENANT)
    },
  },
  watch: {
    isOverlayVisible() {
      if (! this.isOverlayVisible) {
        this.maybeClose()
      }
    },
  },
  created () {
    Object.keys(this.roles).forEach(key => {
      this.roles[key] = this.tenantRoles[key]?.map(code => {
        return code === '*'
          ? this.roleLabels.allMunicipalities
          : this.getMunicipalityOptions.find(mun => mun.value === code)
      })

      !this.roles[key]?.length && delete this.roles[key]
    })
  },
  methods: {
    ...mapMutations('overlay', [
      'hideOverlay',
      'freezeOverlay',
      'unfreezeOverlay',
    ]),
    maybeClose() {
      if (this.busy) return

      this.hideOverlay()
    },
    handleProcessing({ busy }) {
      if (busy) {
        this.freezeOverlay()
      } else {
        this.unfreezeOverlay()
      }
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
    getRoleFeatures ({ role }) {
      const roleFeatures = Object.keys(this.getFeatureAccess).filter(key => this.getFeatureAccess[key].includes(role))
      return roleFeatures.map(feature => this.featureAccess[feature])
    }
  },
}
</script>

<style lang="scss">
.ProfileCard {
  position: relative;
  background: white;
  z-index: 999999;
  opacity: 0.999;
  min-width: 800px;
  max-width: 800px;

  p, .SvgIcon.SvgIcon {
    font-size: 1.15rem;
    cursor: auto;
  }
  &__logout, &__logout .SvgIcon {
    // position: absolute;
    // top: 1.5rem;
    // right: 1rem;
    cursor: pointer !important;

    &:hover {
      color: #b04300
    }
  }
  .flex-3 {
    flex: 0 1 25%;
  }
  .flex-7 {
    flex: 0 1 37%;
  }
  // li::marker {
  //   color: #00B46B;
  // }
}
</style>
